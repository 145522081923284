<template lang="pug">
TableV2(
  :headers="headers"
  :items="items.results"
  :pageCount="items.count"
  :currentPage="items.current"
  :querySearch="getData"
  :isLoading="isLoading"
)
</template>
<script>
import { mapActions } from 'vuex'
export default {
  data () {
    return {
      headers: [
        { value: 'crewing',
          text: this.$t('tableHeaders.crewing'),
          sortable: false },
        { value: 'date_transfer',
          text: this.$t('tableHeaders.date_transfer'),
          sortable: false },
        { value: 'old_type_company',
          text: this.$t('tableHeaders.old_type_company'),
          sortable: false },
        { value: 'new_type_company',
          text: this.$t('tableHeaders.new_type_company'),
          sortable: false },
        { value: 'user',
          text: this.$t('tableHeaders.user_id'),
          sortable: false }
      ],
      isLoading: true,
      items: {}
    }
  },
  methods: {
    ...mapActions(['getCrewingTransfer']),
    async getData (filter) {
      this.isLoading = true
      const response = await this.getCrewingTransfer(filter)
      this.items = response
      this.isLoading = false
    }
  }
}
</script>
